import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

class ContactForm extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="Intro">
            <GridContainer>
              <GridItem md={12} sm={12}>
                <div className={classes.container}>
                  <h3>
                      We are still in the process of building this page. Please get in touch with us and we will be very happy to discuss your requirements.
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(ContactForm);
